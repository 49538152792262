import React from 'react'; 
import PageTitle from '../../components/PageTitle/index';
import './profile.css';
import { Button, Typography, FormHelperText } from '@material-ui/core';
import ImageGrid from './components/ImageGrid'
import { businessDetailsRequest,businessScheduleRequest, createBusinessInfoRequest } from "../../actions/businessDataActions";
import { connect } from 'react-redux';
// import HoursSection from './components/hoursSection'
import AppDialog from "../../components/Dialog/index"
class Profile extends React.Component { 

    constructor(props){
      super(props)
      this.state={
        openDialog:false,
        titleDialog:'',
        business_id:parseInt(this.props.match.params.id),
        dialogType:''
      }
    }

    componentDidMount(){ 
      if(this.state.business_id ){
        let id = this.state.business_id
        this.props.businessDetailsRequest(id)
        this.props.businessScheduleRequest(id)
      }
      window.gtag("event", "Profile_page_viewed")
    }
    
    requestChange= (value) => { 
      window.gtag("event", "profile_request_changes_selected")
      if(value==="desc"){
        this.setState({
          titleDialog:'RESTAURANT DESCRIPTION',
          dialogType:'desc'
        })
      }else { 
        this.setState({
          titleDialog:'AVAILABILITY',
          dialogType:'availability'
        })
      }
      this.setState({
        openDialog:true
      })
    }

    handleClose=()=>{
      this.setState({
        openDialog:!this.state.openDialog
      })
    }

    dialogButton = () => {
      let data = {
        field_type:this.state.titleDialog=== "RESTAURANT DESCRIPTION" ? "our_thoughts" : "opening_hour",
        reason:this.state.description
      }
      this.props.domain_type === "Chain" && Object.assign(data,{business_id:this.state.business_id})
      this.props.createBusinessInfoRequest(data)
      this.setState({ openDialog:!this.state.openDialog})
    }
    
  handleInputChange = (event) =>  {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
    render () { 
        return (
            <div className="old-mp-spacing">
                <PageTitle PageTitle={"Mandie's Cafe's Seated Profile"}/>
                {/* <div className="SectionTitle">
                  <Typography className="primary-normal-font primary-text-color font-size-24 padding-top-20px">Description</Typography>
                  <Button onClick={() => this.requestChange('desc')} className="primary-bold-font primary-button-background-color secondary-text-color align-self-center border-radius-0px padding-top-bottom-8px padding-right-left-24px">Request Changes</Button>
                </div>
                <div className="primary-line-color width-100 align-self-flex-end padding-top-5px border-bottom-width-1px border-bottom-style-solid"/>
                <Typography className="margin-top-45px primary-normal-font primary-text-color font-size-15">{this.props.description}
                </Typography> */}
                  <div className="SectionTitle">
                    <Typography className="primary-text-color primary-normal-font font-size-24 padding-top-20px">Photos</Typography>
                  </div>
                    <div className="primary-line-color width-100 align-self-flex-end padding-top-5px border-bottom-width-1px border-bottom-style-solid"/>
                    <div className="margin-top-30px display-flex flex-direction-row justify-content-center">
                  <ImageGrid isFetching={this.props.isFetchingImages} images={this.props.images} mainImage={this.props.mainImage}></ImageGrid>
                  </div>
                  {/* <div className="SectionTitle">
                    <Typography style={{fontFamily:'Lato', color:"#4d5869", fontSize:"24px",paddingTop:20}}>Hours</Typography>
                    <Button  onClick={() => this.requestChange('schedule')} style={{backgroundColor:"#4d5869",color:"white",alignSelf:"center", borderRadius:'0px',padding:'8px 24px', fontFamily:'Lato-medium'}}>Request Changes</Button>
                  </div>
                  <div style={{width:"100%",alignSelf:'flex-end',paddingTop:5,borderBottom: '1px solid grey'}}/>
                  <HoursSection isFetchingSchedule={this.props.isFetchingSchedule} businessSchedule={this.props.businessSchedule}/> */}
                <AppDialog width="md" key={this.state.dialogType } dialogButton={this.dialogButton} title={this.state.titleDialog} open={this.state.openDialog} handleClose={this.handleClose} buttonTitle={"Request Change" }>
                  <textarea 
                    defaultValue={this.state.dialogType === "desc" ? this.props.description : "" }
                    name="description"
                    autoComplete="off" 
                    onChange = {this.handleInputChange}
                    className="primary-normal-font font-size-14 line-height-text-area border-none width-650px height-220px padding-24px background-color-text-area"
                    placeholder="Let us know what changes you would like to make,and a member of our Restaurant Success team will take care of it!"/>
              </AppDialog>
            </div>
        )    
    }
    
}

const mapStateToProps = state => {
    return {
        isFetchingImages:state.businessData.isFetchingBusinessDetails,
        images:state.businessData.businessDetails.images,
        mainImage:state.businessData.businessDetails.main_image,
        isFetchingSchedule: state.businessData.isFetchingSchedule,
        businessSchedule: state.businessData.businessSchedule,
        description: state.businessData.businessDetails.our_thoughts,
        domain_type: state.businessData.business.domain_type,
        isAuthorized: state.auth.isAuthorized
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      businessDetailsRequest: (id) => {
        dispatch(businessDetailsRequest(id));
      },
      businessScheduleRequest: (id) => {
          dispatch(businessScheduleRequest(id))
      },
      createBusinessInfoRequest:(data)=>{
        dispatch(createBusinessInfoRequest(data))
      }
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile);