import React from 'react'



const BorderHorizontal = (props) => { 
    return ( 
        
        <div className={`primary-line-color width-100 align-self-flex-end padding-top-30px border-bottom-width-1px border-bottom-style-solid ${props.classes}`}/>
        

        
    )

}

export default BorderHorizontal