import React from "react";
import AddNewUserPopUp from "./components/AddNewUserPopUp";
import PageTitle from "../../components/PageTitle/index";
import Grid from "@material-ui/core/Grid";
import TeamDatatable from "../../components/DataTables/teamDatatable";
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import {
  getBusinessEmailsRequest,
  getBusinessAccountsRequest,
  updateBusinessAccountsRequest,
  postBusinessAccountsRequest,
  deleteBusinessAccountsRequest,
  updateBusinessEmailsRequest,
  postBusinessEmailsRequest,
  deleteBusinessEmailsRequest
} from "../../actions/businessDataActions";

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "",
          email: "",
          email_type: "",
          admin: null
        }
      ],
      business_id: parseInt(this.props.match.params.id),
      domain_type: this.props.match.params.entity
    };
  }
  componentDidMount() {
    if (this.state.domain_type !== "chain") {
      this.props.getBusinessEmails(this.state.business_id);
      this.props.getBusinessAccounts(this.state.business_id);
    } else {
      this.props.getBusinessEmails(null);
      this.props.getBusinessAccounts(null);
    }
    window.gtag("event", "Team_page_viewed")
  }

  updateHandler = data => {
    if (data.wasAdmin === true) {
      let user = {
        domain_id: undefined,
        domain_type: "Business",
        email: data.email,
        id: undefined,
        name: data.name
      };

      if (
        data.emailType.Confirmations === true ||
        data.emailType.Cancellations === true ||
        data.emailType.Invoices === true
      ) {
        data.emailType.Admin = false;
        this.createHandler(data);
      }
      let userData = {
        data: user,
        businessId: this.props.business_id,
        id: data.id
      };

      this.props.updateBusinessAccounts(userData);
    }
    if (data.emailType.Confirmations === true && data.wasAdmin === false) {
      let user = {
        domain_id: undefined,
        domain_type: "Business",
        email: data.email,
        id: undefined,
        name: data.name,
        email_type: "confirmation"

        //password:typeof data.password !== undefined ? data.password : ''
      };

      if (data.emailType.Admin === true) {
        data.emailType.Confirmations = false;
        this.createHandler(data);
      }
      let userData = {
        data: user,
        businessId: this.props.business_id,
        id: data.id
      };

      this.props.updateBusinessEmails(userData);
    }
    if (data.emailType.Cancellations === true && data.wasAdmin === false) {
      let user = {
        domain_id: undefined,
        domain_type: "Business",
        email: data.email,
        id: undefined,
        name: data.name,
        email_type: "cancellation"

        //password:typeof data.password !== undefined ? data.password : ''
      };

      if (data.emailType.Admin === true) {
        data.emailType.Cancellations = false;
        this.createHandler(data);
      }
      let userData = {
        data: user,
        businessId: this.props.business_id,
        id: data.id
      };

      this.props.updateBusinessEmails(userData);
    }
    if (data.emailType.Invoices === true && data.wasAdmin === false) {
      let user = {
        domain_id: undefined,
        domain_type: "Business",
        email: data.email,
        id: undefined,
        name: data.name,
        email_type: "invoice"

        //password:typeof data.password !== undefined ? data.password : ''
      };

      if (data.emailType.Admin === true) {
        data.emailType.Reservations = false;
        this.createHandler(data);
      }
      let userData = {
        data: user,
        businessId: this.props.business_id,
        id: data.id
      };

      this.props.updateBusinessEmails(userData);
    }
  };
  deleteHandler = data => {
    if (data.emailType.Admin === true) {
      let user = {
        id: data.id,
        businessId: this.props.business_id
      };
      this.props.deleteBusinessAccounts(user);
    } else {
      let user = {
        id: data.id,
        businessId: this.props.business_id
      };
      this.props.deleteBusinessEmails(user);
    }
  };
  createHandler = data => {
    if (
      data.emailType.Admin === true &&
      (data.wasAdmin === false || data.wasAdmin === undefined)
    ) {
      let user = {
        business_id: this.props.business_id,
        domain_type: "Business",
        email: data.email,
        id: this.props.business_account_id,
        name: data.name,
        password: data.password
      };
      this.props.postBusinessAccounts(user);
    }

    if (
      data.emailType.Confirmations === true &&
      (data.wasAdmin === true || data.wasAdmin === undefined)
    ) {
      if (this.props.domain_type === "Business") {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "confirmation"
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      } else {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "confirmation",
          business_id: this.props.business_id,
          domain_type: "Business",
          id: this.props.business_account_id,
          password: data.password
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      }
    }
    if (
      data.emailType.Cancellations === true &&
      (data.wasAdmin === true || data.wasAdmin === undefined)
    ) {
      if (this.props.domain_type === "Business") {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "cancellation"
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      } else {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "cancellation",
          business_id: this.props.business_id,
          domain_type: "Business",
          id: this.props.business_account_id,
          password: data.password
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      }
    }
    if (
      data.emailType.Invoices === true &&
      (data.wasAdmin === true || data.wasAdmin === undefined)
    ) {
      if (this.props.domain_type === "Business") {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "invoice"
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      } else {
        let userData = {
          email: data.email,
          name: data.name,
          email_type: "invoice",
          business_id: this.props.business_id,
          domain_type: "Business",
          id: this.props.business_account_id,
          password: data.password
        };
        let business_id = this.props.business_id;
        let user = { userData, business_id };
        this.props.postBusinessEmails(user);
      }
    }
  };
  
  getBusinessName() {
    if (this.props.domain_type === "Chain" && this.props.business_id !== null) {
      let selected_business_name = this.props.chain_businesses.filter((business) => {
        return business.id === this.props.business_id;
      })[0].name;
      return selected_business_name;
    } else {
      return this.props.business_name
    }
  };

  requestChangesHandler = event => {
    let businessName = this.getBusinessName()
    window.location.href = `mailto:partnerships@seatedapp.io?subject=${businessName}%20Request%20to%20Update%20Email%20Address`
  };

  render() {
    return (
      <div className="old-mp-spacing">
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <PageTitle pageTitle={"Team"} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >  
            <Button
              onClick={this.requestChangesHandler}
              className="primary-button-background-color secondary-text-color box-shadow-none border-radius-0px primary-medium-font padding-top-bottom-8px padding-right-left-24px"
            >
              REQUEST CHANGES 
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "24px" }}>
            <TeamDatatable
              handleUpdate={this.updateHandler}
              handleDelete={this.deleteHandler}
              data={this.props.teamTableData}
              loading={
                this.props.isFetchingBusinessEmails ||
                this.props.isFetchingBusinessAccounts
              }
              onEditClick={this.onEditHandler}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business_name: state.businessData.business.domain.name,
    business_id: state.businessData.selectedBusiness,
    chain_businesses: state.businessData.business.domain.business_previews,
    business_account_id: state.businessData.business.business_account.id,
    isFetchingBusinessEmails: state.businessData.isFetchingBusinessEmails,
    businessEmails: state.businessData.businessEmails,
    isFetchingBusinessAccounts: state.businessData.isFetchingBusinessAccounts,
    businessAccounts: state.businessData.businessAccounts,
    isAuthorized: state.auth.isAuthorized,
    domain_type: state.businessData.business.domain_type,
    teamTableData: [
      ...state.businessData.businessAccounts,
      ...state.businessData.businessEmails.business_emails
    ].map((user, i) => {
      let isAdmin = false;
      if (i < state.businessData.businessAccounts.length) {
        isAdmin = true;
      }
      return { ...user, isAdmin };
    })
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBusinessEmails: id => {
      dispatch(getBusinessEmailsRequest(id));
    },
    getBusinessAccounts: id => {
      dispatch(getBusinessAccountsRequest(id));
    },
    updateBusinessAccounts: data => {
      dispatch(updateBusinessAccountsRequest(data));
    },
    postBusinessAccounts: data => {
      dispatch(postBusinessAccountsRequest(data));
    },
    deleteBusinessAccounts: id => {
      dispatch(deleteBusinessAccountsRequest(id));
    },
    updateBusinessEmails: data => {
      dispatch(updateBusinessEmailsRequest(data));
    },
    postBusinessEmails: data => {
      dispatch(postBusinessEmailsRequest(data));
    },
    deleteBusinessEmails: id => {
      dispatch(deleteBusinessEmailsRequest(id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Team);
