import React from "react";
import { Grid } from "@material-ui/core";
import BarChart from "../../../../../components/Charts/BarChart";
import { monthlySalesPerformanceTooltipCreator } from "../../../dateRanges";
import BoxViewV2 from "../../../../../components/BoxViewV2/index";
import BorderHorizontal from "../../../../../components/BorderHorizontal";

const MonthlySalesPerformance = (props) => {
  return (
    <Grid container className="margin-left-right-40px">
      <Grid item xs={12} sm={12} md={8}>
        {props.showDefaultScreen ? 
          (<><BarChart
            data={processMonthlySalesDataDefault(
              props.data,
              props.selectedDateRange
            )}
            tooltipFunction={null}
            yTicksMaxValue={9000}
            yTicksCallback={yTicksToLocaleStringCallback}
            showLegend={false}
          />
          <Grid item xs={12} id="monthly-sales-performance-hardcoded-legends-div" className="display-flex flex-direction-row align-items-center">
            <div style={{ height: 14, width: 14, backgroundColor: 'rgb(243, 189, 14)', borderRadius: 2 }}></div>
            <div className="primary-font-normal font-weight-bold font-size-8 margin-left-6px">Confirmed</div>
            <div style={{ height: 14, width: 14, backgroundColor: 'rgb(255, 229, 143)', borderRadius: 2 }} className="margin-left-24px"></div>
            <div className="primary-font-normal font-weight-bold font-size-8 margin-left-6px">Pending</div>
          </Grid></>) : 
          (<BarChart
            data={processMonthlySalesData(
              props.data,
              props.selectedDateRange
            )}
            tooltipFunction={monthlySalesPerformanceTooltipCreator}
            yTicksCallback={yTicksToLocaleStringCallback}
          />)}

      </Grid>
      <Grid item xs={12} sm={12} md={4} className={props.showDefaultScreen ? "opacity-20" : ""}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <BoxViewV2
              containerClasses={"margin-left-32px padding-right-left-24px"}
              number={props.showDefaultScreen ? "0,000" : props.data.statsPerDateRange[props.selectedDateRange].totalGmv}
              hasDollars={true}
              primaryTextFont={"font-size-36"}
              subtitle={"IN SALES"}
            />
            <BorderHorizontal classes="opacity-10 margin-left-36px width-initial" />
          </Grid>
          <Grid item xs={12}>
            <BoxViewV2
              containerClasses={
                "margin-left-32px padding-right-left-24px padding-top-30px"
              }
              number={props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].totalNumberOfTransactions}
              hasDollars={false}
              subtitle={"TRANSACTIONS"}
            />
            <BorderHorizontal classes="opacity-10 margin-left-36px width-initial" />
          </Grid>
          <Grid item xs={12}>
            <BoxViewV2
              containerClasses={
                "margin-left-32px padding-right-left-24px padding-top-30px"
              }
              number={props.showDefaultScreen ? "00.00" : props.data.statsPerDateRange[props.selectedDateRange].averageSpend}
              hasDollars={true}
              subtitle={"AVG SPEND PER TRANSACTION"}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// helper functions
function formatDate(input) {
  // Split the input string into year and month
  const [year, month] = input.split("-");

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert month string to a number and get the corresponding month name
  const monthIndex = parseInt(month, 10) - 1; // Convert to zero-based index
  const monthName = monthNames[monthIndex];

  // Return the formatted string
  return `${monthName} ${year}`;
}

function processMonthlySalesData(data, selectedDateRange) {
  return {
    labels: Object.keys(
      data.statsPerDateRange[selectedDateRange].monthlyStats
    ).map((key) => formatDate(key)),
    type: "bar",
    datasets: [
      {
        label: "Confirmed",
        backgroundColor: "rgb(243, 189, 14)",
        data: Object.values(
          data.statsPerDateRange[selectedDateRange].monthlyStats
        ).map((value) => (value.gmv ? value.gmv : 0)),
        detailedData: Object.values(
          data.statsPerDateRange[selectedDateRange].monthlyStats
        ),
        stack: "stack 0",
        borderRadius: 5,
      },
      {
        label: "Pending",
        backgroundColor: "rgb(255, 229, 143)",
        data: Object.values(
          data.statsPerDateRange[selectedDateRange].monthlyStats
        ).map((value) => (value.pendingGmv ? value.pendingGmv : 0)),
        detailedData: Object.values(
          data.statsPerDateRange[selectedDateRange].monthlyStats
        ),
        stack: "stack 0",
        borderRadius: 5,
      },
    ],
  };
}

function processMonthlySalesDataDefault(data, selectedDateRange) {
  return {
    labels: Object.keys(
      data.statsPerDateRange[selectedDateRange].monthlyStats
    ).map((key) => formatDate(key)),
    type: "bar",
    datasets: [
      {
        label: "Confirmed",
        backgroundColor: "rgb(208, 208, 208)",
        data: Object.values(
          data.statsPerDateRange[selectedDateRange].monthlyStats
        ).map(() => (2000)),
        borderRadius: 5,
      }
    ],
  };
}

function yTicksToLocaleStringCallback(value, index, ticks) {
  if (typeof value === 'number') {
    return "$" + value.toLocaleString(); // Safely convert number to locale string
  }
  return value; // Return value as-is if it's not a number
}

export default MonthlySalesPerformance;
