import React from "react";
import "./loading.css"

const LoadingComponent = ({ className, loading, loadingText, loadingStyle, innerStyle, ...rest }) => {
    return (
        <div
          className={`${className || ""} -loading ${loading ? "-active" : ""}`}
          style={loadingStyle}
          {...rest}
        >
          <div className="-loading-inner" style={innerStyle}>
            {loadingText || "Loading"}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </div>
        </div>
    );
};

export default LoadingComponent;