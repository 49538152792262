import React from "react";
import { Grid } from "@material-ui/core";
import BarChart from "../../../../../components/Charts/BarChart";
import { weeklySalesDistributionTooltipCreator } from "../../../dateRanges";
import BoxViewV2 from "../../../../../components/BoxViewV2/index";
import BorderHorizontal from "../../../../../components/BorderHorizontal";

const defaultWeekdayColor = "#E4E4E4";
const defaultWeekendColor = "#C6C6C6";
const activeColor = "#F2BD0E";

const SalesDistributionSection = (props) => {
  return (
    <Grid
      container
      className="business-insights-card margin-left-right-40px margin-top-30px padding-bottom-40px"
    >
      <Grid item xs={12} className="margin-left-32px">
        <div className="margin-right-auto primary-normal-font font-size-12 font-weight-bold margin-top-bottom-40px display-flex">
          DISTRIBUTION OF SALES
          <div className="opacity-50 margin-left-right-5px">BY DAY OF WEEK</div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className="padding-right-left-24px">
        <BarChart
          data={props.showDefaultScreen ? 
            processWeeklySalesDataDefault(props.data,props.selectedDateRange)
            : processWeeklySalesData(props.data,props.selectedDateRange)
          }
          tooltipFunction={props.showDefaultScreen ? null : weeklySalesDistributionTooltipCreator}
          showLegend={false}
          yTicksCallback={yTicksAppendPercentageCallback}
          xTicksFontSize={14}
          yTicksMaxValue={props.showDefaultScreen ? 100 : null}
          onClickHandler={props.showDefaultScreen ? null : onClickHandler}
        />
      </Grid>
      <Grid item xs={12} md={6} className="padding-right-left-24px">
        <Grid className="" container direction="row">
          <Grid item xs={4}>
            <p className="font-size-12 primary-normal-font primary-text-color line-height-15px opacity-50 margin-top-0 margin-bottom-8px">
              {props.showDefaultScreen ? "00" : getWeekdaysDistribution(props.data.statsPerDateRange[props.selectedDateRange].weeklyStats)}%
            </p>
            <BoxViewV2
              containerClasses={""}
              number={props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].averageSalesMondayTilThursday}
              hasDollars={true}
              primaryTextFont={"font-size-30"}
              subtitle={"MON - THUR"}
              numberClasses={props.showDefaultScreen ? "opacity-20" : ""}
              subtitleClasses={props.showDefaultScreen ? "opacity-50" : ""}
            />
          </Grid>
          <Grid item>
            <p className="font-size-12 primary-normal-font primary-text-color line-height-15px opacity-50 margin-top-0 margin-bottom-8px padding-left-right-16px">
              {props.showDefaultScreen ? "00" : getWeekendDistribution(props.data.statsPerDateRange[props.selectedDateRange].weeklyStats)}%
            </p>
            <BoxViewV2
              containerClasses={"padding-left-right-16px"}
              number={props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].averageSalesFridayTilSunday}
              hasDollars={true}
              primaryTextFont={"font-size-30"}
              subtitle={"FRI - SUN"}
              numberClasses={props.showDefaultScreen ? "opacity-20" : ""}
              subtitleClasses={props.showDefaultScreen ? "opacity-50" : ""}
            />
          </Grid>
          <Grid item xs={12} className="margin-right-10px">
            <BorderHorizontal classes="opacity-10 width-initial padding-top-24px" />
          </Grid>
          <Grid item className="description-text">
            <p className="font-size-12 opacity-50 padding-top-12px">
              Weekly dining behavior depends on a variety of factors
              (seasonality, weather, etc.), and we market your restaurant to
              diners to fill empty tables throughout the week.
            </p>
            <p className="font-size-12 opacity-50 padding-top-12px">
              The reward rate you offer can have a major impact on when guests
              are dining with you – restaurants that offer higher rewards on
              weekdays drive ~25% more volume! Want to adjust your profile to
              drive more weekday business? Scroll down to chat with your Seated
              rep!
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

function abbreviateDay(day) {
  const dayMap = {
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
  };

  return dayMap[day.toLowerCase()] || "Invalid day";
}

function getOrderedWeeklyStats(weeklyStatsData) {
  const daysOrder = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
  const orderedWeeklyStatsData = {};

  daysOrder.forEach(day => {
    if (weeklyStatsData[day]) {
      orderedWeeklyStatsData[day] = weeklyStatsData[day];
    }
  });

  return orderedWeeklyStatsData;
}

function getWeekdaysDistribution(weeklyStats) {
  return weeklyStats.MONDAY.distribution + weeklyStats.TUESDAY.distribution + weeklyStats.WEDNESDAY.distribution + weeklyStats.THURSDAY.distribution;
}

function getWeekendDistribution(weeklyStats) {
  return weeklyStats.FRIDAY.distribution + weeklyStats.SATURDAY.distribution + weeklyStats.SUNDAY.distribution;
}

function processWeeklySalesData(data, selectedDateRange) {
  return {
    labels: Object.keys(
      getOrderedWeeklyStats(data.statsPerDateRange[selectedDateRange].weeklyStats)
    ).map((day) => abbreviateDay(day)),
    type: "bar",
    datasets: [
      {
        backgroundColor: [
          defaultWeekdayColor,
          defaultWeekdayColor,
          defaultWeekdayColor,
          defaultWeekdayColor,
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
        ],
        data: Object.values(
          getOrderedWeeklyStats(data.statsPerDateRange[selectedDateRange].weeklyStats)
        ).map((value) => value.distribution),
        detailedData: Object.values(
          getOrderedWeeklyStats(data.statsPerDateRange[selectedDateRange].weeklyStats)
        ),
        borderRadius: 5,
      },
    ],
  };
}

function processWeeklySalesDataDefault(data, selectedDateRange) {
  return {
    labels: Object.keys(
      getOrderedWeeklyStats(data.statsPerDateRange[selectedDateRange].weeklyStats)
    ).map((day) => abbreviateDay(day)),
    type: "bar",
    datasets: [
      {
        backgroundColor: [
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
          defaultWeekendColor,
        ],
        data: Object.values(
          getOrderedWeeklyStats(data.statsPerDateRange[selectedDateRange].weeklyStats)
        ).map(() => 100/7),
        borderRadius: 5,
      },
    ],
  };
}

function yTicksAppendPercentageCallback(value, index, ticks) {
  return value + "%";
}

function onClickHandler (event, elements) {
  if (elements.length > 0 && elements[0].element) {
    if (elements.length > 0 && elements[0].element) {
      const chart = elements[0].element.$context.chart;
      const datasetIndex = elements[0].datasetIndex;
      const index = elements[0].index;

      // Reset all bars to their original colors
      chart.data.datasets[datasetIndex].backgroundColor = [
        defaultWeekdayColor,
        defaultWeekdayColor,
        defaultWeekdayColor,
        defaultWeekdayColor,
        defaultWeekendColor,
        defaultWeekendColor,
        defaultWeekendColor,
      ];

      // Set the clicked bar to active color
      chart.data.datasets[datasetIndex].backgroundColor[index] = activeColor;

      chart.update();
    }
  }
}

export default SalesDistributionSection;
