import React, { useRef, useEffect, useState } from "react";

const seated_logo = require("../../assets/icons/seated_logo.png");
const bilt_logo = require("../../assets/icons/bilt_logo.png");
const citi_logo = require("../../assets/icons/citi_logo.png");
const dinova_logo = require("../../assets/icons/dinova_logo.png");
const upside_logo = require("../../assets/icons/upside_logo.png");
const rakuten_logo = require("../../assets/icons/rakuten_logo.png");
const mastercard_logo = require("../../assets/icons/mastercard_logo.png");
const carousel_forward = require("../../assets/icons/carousle-forward.png");

const logos = {
  SEATED: seated_logo,
  BILT: bilt_logo,
  CITI: citi_logo,
  DINOVA: dinova_logo,
  UPSIDE: upside_logo,
  RAKUTEN: rakuten_logo,
  MASTERCARD: mastercard_logo,
};

// todo: add logic for BE support
const gradients = {
  SEATED: "linear-gradient(165.89deg, rgba(242, 189, 14, 0.2) -76.69%, rgba(255, 255, 255, 0.2) 43.58%)",
  BILT: "linear-gradient(165.89deg, rgba(0, 0, 0, 0.2) -76.69%, rgba(255, 255, 255, 0.2) 43.58%)",
  CITI: "linear-gradient(165.89deg, rgba(37, 91, 227, 0.2) -76.69%, rgba(255, 255, 255, 0.2) 43.58%)",
  DINOVA: "linear-gradient(165.89deg, rgba(37, 78, 35, 0.2) -76.69%, rgba(255, 255, 255, 0.2) 43.58%)"
}

const ScrollablePartners = (props) => {
  const scrollContainerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const totalWidth = container.scrollWidth; // Total scrollable width
        setIsOverflowing(totalWidth > container.clientWidth + 66);
      }
    };

    checkOverflow(); // Initial check

    // Add event listener for resize
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const scrollRight = () => {
    const container = scrollContainerRef.current;

    const card = container.querySelector(".partner-card");
    const cardRect = card.getBoundingClientRect();
    const cardWidth = cardRect.width;

    const gap = 20;

    const totalScrollAmount = cardWidth + gap;

    const currentScroll = container.scrollLeft;
    const maxScroll = container.scrollWidth - container.clientWidth;

    const newScrollPosition = currentScroll + totalScrollAmount - 10;

    const isAtEnd = newScrollPosition >= maxScroll;

    if (isAtEnd) {
      container.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    } else {
      container.scrollBy({
        left: totalScrollAmount,
        behavior: "smooth",
      });
    }
  };

  const sanitizeBulletPoints = (bulletPoints) => {
    return bulletPoints
      .replace("*", "")
      .split("\n*")
      .map((point) => point.trim("*"));
  };

  return (
    <div className="partners-section">
      <h5 className="partner-section-heading">
        Partners in the Seated Marketing Network
      </h5>
      <div className="partners-container">
        <div className="scrollable-partners" ref={scrollContainerRef}>
          {getOrderedPartners(props.partners).map((partner, index) => (
            <div
              key={index}
              className="partner-card"
              style={{ background: gradients[partner.name] }}
            >
              <img
                src={logos[partner.name]}
                alt={partner.name}
                className="partner-logo"
              />
              <p>{partner.description}</p>
              <ul className="card-details">
                {sanitizeBulletPoints(partner.bulletPoints).map((bullet, i) => (
                  <li key={i}>{bullet}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {isOverflowing && (
          <button className="scroll-right-arrow" onClick={scrollRight}>
            <img
              className="right-arrow-icon"
              src={carousel_forward}
              alt="Scroll Right"
            />
          </button>
        )}
      </div>
    </div>
  );
};

function getOrderedPartners(partners) {
  const partnersOrder = ["SEATED", "BILT", "DINOVA", "MASTERCARD", "CITI", "UPSIDE", "RAKUTEN"];

  return partners.sort((a, b) => {
    return partnersOrder.indexOf(a.name) - partnersOrder.indexOf(b.name);
  });
}

export default ScrollablePartners;
