import React from "react";

const seated_rep_icon = require("../../assets/icons/seated_rep.png");
const opt_in_for_more_icon = require("../../assets/icons/opt_in_for_more_icon.png");
const refer_and_earn_icon = require("../../assets/icons/refer_and_earn_icon.png");

const createResourcesData = (accountManager = {}) => {
  return [
    {
      title: "Chat with Your Seated Rep",
      description: "Email your account manager with any questions. We’re here to help.",
      buttonText: "GET IN TOUCH",
      icon: accountManager.avatarUrl,
      fallbackIcon: seated_rep_icon,
      iconHeight: "90px",
      iconWidth: "90px",
      email: accountManager.email,
      subject: 'Chat with Your Seated Rep',
      body: ''
    },
    {
      title: "Opt In for More with Seated",
      description: "Request a marketing feature for your restaurant in an upcoming campaign!",
      buttonText: "GET IN TOUCH",
      icon: opt_in_for_more_icon,
      iconHeight: "80px",
      iconWidth: "90.91px",
      email: 'restaurantmarketing@seatedapp.io', 
      subject: 'Marketing Feature Request',
      body: ' I would like to learn more about upcoming marketing campaigns. My info (name, email, phone):  // Restaurant name:'
    },
    {
      title: "Refer and Earn",
      description: "Want to refer a restaurant to join Seated? Earn up to $250!",
      buttonText: "REFER NOW",
      icon: refer_and_earn_icon,
      iconHeight: "72px",
      iconWidth: "88px",
      email: 'restaurantmarketing@seatedapp.io', 
      subject: 'Referral Program Inquiry',
      body: `I am interested in the referral program. My info (name, email, phone):  // Referral info: (restaurant name, contact name, phone)`
    },
  ]
}

const ResourcesSection = (props) => {
  const handleEmailClick = (email, subject, body) => {
    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.location.href = mailtoLink;
  };

  return (
    <div className="resources-section">
      <div className="ellipse-7"></div>
      <div className="ellipse-8"></div>
      <div className="ellipse-9"></div>
      <h2 className="resources-title">Get in Touch</h2>
      <div className="resources-container">
        {createResourcesData(props.resourceData.accountManager).map((resource, index) => (
          <div key={index} className="resource-card">
            <img
              src={resource.icon}
              alt={resource.title}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = resource.fallbackIcon;
              }}
              className="resource-icon"
              style={{ height: resource.iconHeight, width: resource.iconWidth }}
            />
            <h3 className="resource-title">{resource.title}</h3>
            <p className="resource-description">{resource.description}</p>
            <button
              className="resource-button"
              onClick={() => handleEmailClick(resource.email, resource.subject, resource.body)}
            >
              {resource.buttonText}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourcesSection;
