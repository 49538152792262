import React from "react";
import "./dashBoard.css";
import BoxView from "../../components/BoxView";
import BorderVertical from "../../components/BorderVertical";
import BorderHorizontal from "../../components/BorderHorizontal";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import TitleBoxView from "../TitleBoxView";
import DropDown from "../../components/Dropdown/index";
import PageTitle from "../PageTitle";

function DineInDashboard(props) {
  const items = ["All time", "Last 90 days", "Last 30 days", "Last 7 days"];
  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-evenly"
        style={{ marginBottom: 50 }}
        className="old-mp-spacing"
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div style={{ marginBottom: 50, alignContent: "center" }}>
              <PageTitle pageTitle={"Business Statistics"} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="display-flex justify-content-right">
            <DropDown
              future={false}
              handleSelect={props.onSelectHandler}
              items={items}
              selectedOptionNumber={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TitleBoxView
              pageTitle="Revenue Driven"
              titleNumber={props.dashboardData.revenueDriven}
              hasDollars={true}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly">
          <BorderHorizontal />
          <Grid item container direction="row" justify="space-evenly">
            <Grid item style={{ paddingTop: 100 }}>
              <BoxView
                title="Average Spend Per Party"
                number={props.dashboardData.avgSpend}
                hasDollars={true}
              />
            </Grid>
            <Hidden smDown>
              <Grid item style={{ paddingTop: 30 }}>
                <BorderVertical />
              </Grid>
            </Hidden>
            <Grid item style={{ paddingTop: 100 }}>
              <BoxView
                title="Number of Parties Sent"
                number={props.dashboardData.numOfPartiesSent}
                hasDollars={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <BorderHorizontal />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
}

export default DineInDashboard;
