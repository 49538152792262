import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isFetching: true,
  dashboardData: {},
  businessPerformanceData: {},
  partnersData: {},
  businessResourceData: {},
  businessReviews: {},
  business: { domain: { image: {} } },
  reservations: {},
  businessDataFetched: false,
  billPayments: {},
  isFetchingBillPayments: true,
  statements: {},
  isFetchingReservations: true,
  isFetchingBusinessDetails: true,
  isFetchingSchedule: true,
  isFetchingReviews: true,
  isFetchingDashboardData: true,
  isFetchingBusinessPerformanceData: true,
  isFetchingPartnersData: true,
  isFetchingbusinessResourceData: true,
  isFetchingBusinessEmails: true,
  isFetchingBusinessAccounts: true,
  isFetchingStatements: false,
  isDownloadingStatements: false,
  isUpdatingBusinessAccounts: false,
  isUploadingBusinessImage: false,
  businessSchedule: {},
  businessDetails: {},
  businessEmails: { business_emails: [], business_emails_count: 0 },
  businessAccounts: [],
  selectedBusiness: null,
  selectedChain: null,
  isPostingBusinessAccounts: false,
  businessAccountsUser: {
    domain_id: "",
    name: "",
    email: "",
    email_type: "admin",
    password: ""
  },
  infoData: {},
  isSendingData: false,
  message: { message: "", variant: "" },
  businessImage: { id: "", style: {} },
  isRequestingConfirmation: false,
  confirmationMessage: { message: "", variant: "" },
  isResettingPassword: false, 
  isUpdatingPassword: false,
  passwordUpdated:false,
  merchantTermsAccepted:false,
  isFetchingUnpaidBalance: true
};
const businessDataReducer = (state = initialState, action) => {
  // console.log(action.type)
  switch (action.type) {
    case actionTypes.GET_BUSINESS_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        businessDataFetched: false
      });
    case actionTypes.GET_BUSINESS_DATA_REQUEST_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        message: action.payload.message
      });
    case actionTypes.GET_BUSINESS_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        businessDataFetched: true,
        business: action.payload,
        selectedBusiness:
          action.payload.domain_type === "Business"
            ? action.payload.business_account.domain_id
            : null,
        businessImage:
          action.payload.domain_type === "Chain"
            ? action.payload.domain.image
            : action.payload.domain.main_image,
        selectedChain:
          action.payload.domain_type === "Chain"
            ? action.payload.business_account.domain_id
            : null
      });
    case actionTypes.BUSINESS_PERFORMANCE_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetchingBusinessPerformanceData: true
      });
    case actionTypes.BUSINESS_PERFORMANCE_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingBusinessPerformanceData: false,
        businessPerformanceData: action.payload
      });
    case actionTypes.DASHBOARD_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetchingDashboardData: true
      });
    case actionTypes.DASHBOARD_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDashboardData: false,
        dashboardData: action.payload
      });
    case actionTypes.PARTNERS_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetchingPartnersData: true
      });
    case actionTypes.PARTNERS_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingPartnersData: false,
        partnersData: action.payload
      });
    case actionTypes.BUSINESS_RESOURCE_DATA_REQUEST:
      return Object.assign({}, state, {
        isFetchingbusinessResourceData: true
      });
    case actionTypes.BUSINESS_RESOURCE_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingbusinessResourceData: false,
        businessResourceData: action.payload
      });
    case actionTypes.BUSINESS_REVIEWS_REQUEST:
      return Object.assign({}, state, {
        isFetchingReviews: true
      });
    case actionTypes.BUSINESS_REVIEWS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingReviews: false,
        businessReviews: action.payload
      });
    case actionTypes.GET_RESERVATIONS_REQUEST:
      return Object.assign({}, state, {
        isFetchingReservations: true
      });
    case actionTypes.GET_RESERVATIONS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingReservations: false,
        reservations: action.payload
      });
    case actionTypes.BUSINESS_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetchingBusinessDetails: true
      });
    case actionTypes.BUSINESS_DETAILS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingBusinessDetails: false,
        businessDetails: action.payload
      });
    case actionTypes.GET_STATEMENTS_REQUEST:
      return Object.assign({}, state, {
        isFetchingStatements: true
      });
    case actionTypes.GET_STATEMENTS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingStatements: false,
        statements: action.payload
      });
    case actionTypes.STATEMENTS_XLS_REQUEST:
      return Object.assign({}, state, {
        isDownloadingStatements: true
      });
    case actionTypes.STATEMENTS_REQUEST_XLS_FAILED:
      return Object.assign({}, state, {
        isDownloadingStatements: false,
        message: action.payload
      });
    case actionTypes.STATEMENTS_REQUEST_XLS_SUCCESS:
      return Object.assign({}, state, {
        isDownloadingStatements: false,
        message: action.payload
      });
    case actionTypes.BUSINESS_SCHEDULE_REQUEST:
      return Object.assign({}, state, {
        isFetchingSchedule: true
      });
    case actionTypes.BUSINESS_SCHEDULE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSchedule: false,
        businessSchedule: action.payload
      });
    case actionTypes.SET_SELECTED_BUSINESS:
      return Object.assign({}, state, {
        selectedBusiness: action.payload
      });
    case actionTypes.SET_SELECTED_CHAIN:
      return Object.assign({}, state, {
        selectedChain: action.payload
      });
    case actionTypes.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        message: { message: "", variant: "" }
      });
    case actionTypes.GET_BUSINESS_EMAILS:
      return Object.assign({}, state, {
        isFetchingBusinessEmails: true
      });
    case actionTypes.GET_BUSINESS_EMAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingBusinessEmails: false,
        businessEmails: action.payload
      });
    case actionTypes.GET_BUSINESS_EMAILS_FAILED:
      return Object.assign({}, state, {
        isFetchingBusinessEmails: false
      });
    case actionTypes.GET_BUSINESS_ACCOUNTS:
      return Object.assign({}, state, {
        isFetchingBusinessAccounts: true
      });
    case actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingBusinessAccounts: false,
        businessAccounts: action.payload
      });
    case actionTypes.GET_BUSINESS_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        isFetchingBusinessAccounts: false
      });
    case actionTypes.POST_BUSINESS_ACCOUNTS:
      return Object.assign({}, state, {
        isPostingBusinessAccounts: true
      });
    case actionTypes.POST_BUSINESS_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        isPostingBusinessAccounts: false,
        message: action.payload.message
      });
    case actionTypes.POST_BUSINESS_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        isFetchingBusinessAccounts: false,
        message: action.payload
      });
    case actionTypes.UPDATE_BUSINESS_ACCOUNTS:
      return Object.assign({}, state, {
        isUpdatingBusinessAccounts: true
      });
    case actionTypes.UPDATE_BUSINESS_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingBusinessAccounts: false,
        message: action.payload.message
      });
    case actionTypes.UPDATE_BUSINESS_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        isUpdatingBusinessAccounts: false,
        message: action.payload
      });
    case actionTypes.DELETE_BUSINESS_ACCOUNTS:
      return Object.assign({}, state, {
        isDeletingBusinessAccounts: true
      });
    case actionTypes.DELETE_BUSINESS_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        isDeletingBusinessAccounts: false,
        message: action.payload.message
      });
    case actionTypes.DELETE_BUSINESS_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        isDeletingBusinessAccounts: false,
        message: action.payload
      });
    case actionTypes.POST_BUSINESS_EMAILS:
      return Object.assign({}, state, {
        isPostingBusinessEmails: true
      });
    case actionTypes.POST_BUSINESS_EMAILS_SUCCESS:
      return Object.assign({}, state, {
        isPostingBusinessEmails: false,
        message: action.payload.message
      });
    case actionTypes.POST_BUSINESS_EMAILS_FAILED:
      return Object.assign({}, state, {
        isFetchingBusinessEmails: false,
        message: action.payload
      });
    case actionTypes.UPDATE_BUSINESS_EMAILS:
      return Object.assign({}, state, {
        isUpdatingBusinessEmails: true
      });
    case actionTypes.UPDATE_BUSINESS_EMAILS_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingBusinessEmails: false,
        message: action.payload.message
      });
    case actionTypes.UPDATE_BUSINESS_EMAILS_FAILED:
      return Object.assign({}, state, {
        isUpdatingBusinessEmails: false,
        message: action.payload
      });
    case actionTypes.DELETE_BUSINESS_EMAILS:
      return Object.assign({}, state, {
        isDeletingBusinessEmails: true
      });
    case actionTypes.DELETE_BUSINESS_EMAILS_SUCCESS:
      return Object.assign({}, state, {
        isDeletingBusinessEmails: false,
        message: action.payload.message
      });
    case actionTypes.DELETE_BUSINESS_EMAILS_FAILED:
      return Object.assign({}, state, {
        isDeletingBusinessEmails: false,
        message: action.payload
      });
    case actionTypes.CHANGE_BUSINESS_ACCOUNT_USER:
      return Object.assign({}, state, {
        businessAccounts: action.payload
      });
    case actionTypes.CREATE_BUSINESS_INFO_REQUEST:
      return Object.assign({}, state, {
        infoData: action.payload,
        isSendingData: true
      });
    case actionTypes.CREATE_BUSINESS_INFO_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isSendingData: false,
        message: action.payload
      });
    case actionTypes.CREATE_BUSINESS_INFO_REQUEST_FAILED:
      return Object.assign({}, state, {
        isSendingData: false,
        message: action.payload
      });
    case actionTypes.UNSUBSCRIBE_EMAILS_SUCCESS:
      return Object.assign({}, state, {
        message: action.payload
      });
    case actionTypes.UPLOAD_BUSINESS_IMAGE:
      return Object.assign({}, state, {
        isUploadingBusinessImage: true
      });
    case actionTypes.UPLOAD_BUSINESS_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        isUploadingBusinessImage: false,
        businessImage: action.payload
      });
    case actionTypes.UPLOAD_BUSINESS_IMAGE_FAILED:
      return Object.assign({}, state, {
        isUploadingBusinessImage: false
      });
    case actionTypes.GET_BILL_PAYMENTS_REQUEST:
      return Object.assign({}, state, {
        isFetchingBillPayments: true,
        billPayments: action.payload
      });
    case actionTypes.GET_BILL_PAYMENTS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingBillPayments: false,
        billPayments: action.payload
      });
    case actionTypes.GET_BILL_PAYMENTS_FAILED:
      return Object.assign({}, state, {
        isFetchingBillPayments: false
      });
    case actionTypes.GET_UNPAID_BALANCE_REQUEST:
      return Object.assign({}, state, {
        isFetchingUnpaidBalance: true,
        unpaidBalance: action.payload
      });
    case actionTypes.GET_UNPAID_BALANCE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingUnpaidBalance: false,
        unpaidBalance: action.payload
      });
    case actionTypes.GET_UNPAID_BALANCE_FAILED:
      return Object.assign({}, state, {
        isFetchingUnpaidBalance: false
      });
    case actionTypes.CONFIRM_RESERVATION_REQUEST_FAILED:
      return Object.assign({}, state, {
        confirmationMessage: action.payload
      });
    case actionTypes.IS_REQUESTING_CONFIRMATION:
      return Object.assign({}, state, {
        isRequestingConfirmation: action.payload
      });
    case actionTypes.CONFIRM_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        confirmationMessage: action.payload
      });
    case actionTypes.REJECT_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        confirmationMessage: action.payload
      });
      case actionTypes.RESET_PASSWORD:
      return Object.assign({}, state, {
        isResettingPassword: true
      });
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isResettingPassword: false,
        message: action.payload
      });
    case actionTypes.RESET_PASSWORD_FAILED:
      return Object.assign({}, state, {
        isResettingPassword: false,
        message: action.payload
      });
    case actionTypes.UPDATE_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isUpdatingPassword: true,
        passwordUpdated:false
      });
    case actionTypes.UPDATE_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingPassword: false,
        message: action.payload,
        passwordUpdated:true
      });
    case actionTypes.UPDATE_PASSWORD_REQUEST_FAILED:
      return Object.assign({}, state, {
        isUpdatingPassword: false,
        message: action.payload
      });
    case actionTypes.GET_BUSINESS_ACCOUNTS_SUCCESS_V2:
      window.localStorage.setItem("terms_accepted", action.payload.merchantTermsAccepted);
      return Object.assign({}, state, {
        merchantTermsAccepted: action.payload.merchantTermsAccepted
      });
    case actionTypes.UPDATE_BUSINESS_ACCOUNTS_SUCCESS_V2:
      window.localStorage.setItem("terms_accepted", action.payload.merchantTermsAccepted);
      return Object.assign({}, state, {
        merchantTermsAccepted: action.payload.merchantTermsAccepted
      });
      
    default:
      return state;
  }
};

export default businessDataReducer;
