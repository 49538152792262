import React from "react";
import { connect } from "react-redux";
import { dashboardRequest, businessPerformanceRequest, partnersRequest, businessResourceRequest } from "../../actions/businessDataActions";
import moment from "moment";
import "./home.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import { resetIsAuthorized } from "../../actions/auth";
import DineInDashboard from "../../components/DashBoardView/dineIn";
import SahDashboard from "../../components/DashBoardView/sah";
import ScrollablePartners from '../../components/DashBoardView/ScrollablePartners';
import ResourcesSection from '../../components/DashBoardView/ResourcesSection';
import { dateRanges, defaultDateRange } from "./dateRanges";
import BusinessPerformanceSection from "./components/BusinessPerformanceSection/index";
import MenuBar from "./components/MenuBar";
import { PAST_3_MONTHS, PAST_6_MONTHS, PAST_12_MONTHS } from "./dateRanges";

class Home extends React.Component {

  getAllTimes(){
    return {
      end_time: this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - 1000)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm"),
    }
  };

  getLastXDays(item) {
    var new_item = null;
    var months = null;
    var start_month=null;
    var end_month=null;
    for (var i = 1; i < 12; i++) {
      if (item === 30 * i) {
        start_month = this.$moment().month() - i;
        end_month = this.$moment().month();
        if (end_month - start_month > 0) {
          months = Number(end_month - start_month);
        } else {
          months = Number(12 + end_month - start_month);
        }

        for (var j = 1; j <= months; j++) {
          if (this.$moment().month() - j === (3 || 5 || 8 || 10)) {
            new_item += 30;
          } else if (this.$moment().month() - j === 1) {
            new_item += 28;
          } else {
            new_item += 31;
          }
        }
      }
    }
    if (!new_item) {
      new_item = item;
    }
    
    return {
      end_time: this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - 1 - new_item)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm")
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      data :{
        ...this.getLastXDays(30),  
        business_id: parseInt(this.props.match.params.id),
        product: "DINE_IN",
        date_range: defaultDateRange
      }
    };
  }
  $moment = moment;

  componentDidMount() {
    this.getDashboard();
    this.getPartnersData();
    this.getBusinessResourceData();
    window.gtag("event", "Home_page_viewed")
  }
  getDashboard() {
    let data = this.state.data;
    let domain_type = this.props.match.params.entity;
    if (domain_type !== "chain") {
      this.props.businessPerformanceRequest(data);
    } else {
      delete data.business_id;
      this.props.dashboardRequest(data);
      this.setState(this.getLastXDays(30));
    }
  }
  getPartnersData() {
    let data = this.state.data;
    this.props.partnersRequest(data);
  }
  getBusinessResourceData() {
    let data = this.state.data;
    this.props.businessResourceRequest(data);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if ((parseInt(this.props.match.params.id) === this.props.selectedBusiness) & (prevProps.selectedBusiness === null)) {
      let data = { business_id: this.props.match.params.id };
      this.props.businessPerformanceRequest(data);
    } else if (this.props.match.params.entity !== prevProps.match.params.entity) {
      this.getDashboard();
    }
    if(this.props.selectedBusiness === null & prevProps.selectedBusiness !== null){
      let data = { ...this.state, product: this.state.data.product };
      delete data.business_id;
      this.props.dashboardRequest(data);
    }
  }

  onSelect = (string_item, options) => {
    console.log(string_item, options)
    if (string_item == "custom_range"){
      var state = Object.assign(this.state.data, {
        start_time: this.$moment(options.start_time).format("YYYY-MM-DD HH:mm"),
        end_time: this.$moment(options.end_time).hours(23).minutes(59).format("YYYY-MM-DD HH:mm"),
        business_id: parseInt(this.props.match.params.id),
        product: this.state.data.product
      });
      window.gtag("event", "business_stats_time_range_filtered", {start_time: state.start_time, end_time: state.end_time})
      this.setState({ data:state }, () => this.getDashboard());

      return;
    }

    
    if (string_item !== "All time") {
      if (string_item !== "") {        
        var item = string_item.match(/\d/g);
        if (item) {
          item = Number(item.join(""));
          var { end_time, start_time } = this.getLastXDays(item);
      } else {
        
        start_time = this.$moment(options.start_time).format(
          "YYYY-MM-DD HH:mm"
        );
        end_time = this.$moment(options.end_time)
          .hours(23)
          .minutes(59)
          .format("YYYY-MM-DD HH:mm");
      }
    } else {
      ({ end_time, start_time } = this.getLastXDays(30));
    }
    var state = Object.assign(this.state.data, {
      start_time: start_time,
      end_time: end_time,
      business_id: parseInt(this.props.match.params.id),
      product: this.state.data.product
    });
    window.gtag("event", "business_stats_time_range_filtered", {start_time: state.start_time, end_time: state.end_time})
    this.setState({ data:state }, () => this.getDashboard());
  } else {
    window.gtag("event", "business_stats_time_range_filtered", this.getAllTimes())
    this.setState({data:{...this.getAllTimes(), business_id: parseInt(this.props.match.params.id), product: this.state.data.product}},
    ()=>this.getDashboard());
  };
  }

  // todo: remove if not being used
  changeFilterBy = params => {
    var state = Object.assign(this.state.data, {
      business_id: parseInt(this.props.match.params.id),
      product: params
    });
    window.gtag("event", "business_stats_modality_filtered", {modality_selected: params})
    this.setState({ data:state }, () => this.getDashboard());
  };

  handleDateRangeBtnClick = (selectedDateRange) => {
    this.setState({data: {...this.state.data, date_range: selectedDateRange}});
  }

  render() {
    let domainType = this.props.match.params.entity;
    let dashboardData = this.props.dashboardData;
    let businessPerformanceData = this.props.businessPerformanceData;
    let partnersData = this.props.partnersData;
    let businessResourceData = this.props.businessResourceData;
    let defaultProduct = this.props.dashboardData.defaultProduct;
    let businessPerformanceDataNotComplete = false;
    let partnersOrResourceDataNotComplete = false;
    let detailView;
    const product = this.state.data.product;
    if (product == "DINE_IN" || (product == "DEFAULT" && defaultProduct == "DINE_IN")) {
      if (domainType == 'chain') {
        detailView = <DineInDashboard dashboardData={this.props.dashboardData} onSelectHandler={this.onSelect} />
      } else {
        if (!businessPerformanceData.statsPerDateRange || !businessPerformanceData.statsPerDateRange[PAST_3_MONTHS] 
          || !businessPerformanceData.statsPerDateRange[PAST_6_MONTHS] || !businessPerformanceData.statsPerDateRange[PAST_12_MONTHS]
        ) {
          businessPerformanceDataNotComplete = true;
          console.log("Business performance data not complete yet");
        }
        if (!partnersData || !businessResourceData) {
          partnersOrResourceDataNotComplete = true;
          console.log("Partners or Resource data not complete yet");
        }
        detailView = <BusinessPerformanceSection data={businessPerformanceData} selectedDateRange={this.state.data.date_range} />
      }
    } else if (product == "SAH" || (product == "DEFAULT" && defaultProduct == "SAH")) {
      detailView =  <SahDashboard dashboardData={this.props.dashboardData}/>
    } else {
      detailView =  null;
    }
    return (
      <div className="home-page-section">
        {domainType !== 'chain' ? (
          <MenuBar 
            title={"Business Performance Dashboard"}
            showDateRangeButtons={true}
            dateRange={this.state.data.date_range}
            dateChangeHandler={this.handleDateRangeBtnClick}
            dateRanges={dateRanges}
          />
        ) : ''
        }
        <div style={{ overflow: "hidden"}}>
          {this.props.isFetching || businessPerformanceDataNotComplete ? (
            <CircularProgress
              style={{ position: "fixed", bottom: "50%", right: "40%" }}
            />
          ) : (
            <>
              <Grid
                container
                direction="column"
                justify="space-evenly"
                style={{ paddingBottom: 50 }}
                className={domainType !== "chain" ? "custom-gradient" : ''}
              >
                <Grid container direction="row" justify="space-evenly">
                  {domainType == 'chain' && Object.keys(dashboardData).length == 0 && Object.keys(businessPerformanceData).length == 0 ? 
                    <span className="primary-medium-font font-size-24 margin-top-45px">No businesses are linked to this chain!</span>
                    : 
                    ''
                  }
                  {detailView}
                </Grid>
              </Grid>
              {domainType !== 'chain' ? (
                <>
                  {partnersOrResourceDataNotComplete ? (
                    <div className="display-flex align-items-center justify-content-center height-300px">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <ScrollablePartners partners={partnersData} />
                      <ResourcesSection resourceData={businessResourceData} />
                    </>
                  )}
                </>
                ) : ''}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboardData: state.businessData.dashboardData,
    businessPerformanceData: state.businessData.businessPerformanceData,
    partnersData: state.businessData.partnersData,
    businessResourceData: state.businessData.businessResourceData,
    isFetching: state.businessData.isFetchingDashboardData && state.businessData.isFetchingBusinessPerformanceData,
    selectedBusiness:state.businessData.selectedBusiness,
    isAuthorized: state.auth.isAuthorized
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dashboardRequest: data => {
      dispatch(dashboardRequest(data));
    },
    businessPerformanceRequest: data => {
      dispatch(businessPerformanceRequest(data));
    },
    partnersRequest: data => {
      dispatch(partnersRequest(data));
    },
    businessResourceRequest: data => {
      dispatch(businessResourceRequest(data));
    },
    resetIsAuthorized: () => {
      dispatch(resetIsAuthorized());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
