export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS';
export const AUTH_REQUEST_FAILED = 'AUTH_REQUEST_FAILED';

export const GET_BUSINESS_DATA_REQUEST = 'GET_BUSINESS_DATA_REQUEST';
export const GET_BUSINESS_DATA_REQUEST_SUCCESS = 'GET_BUSINESS_DATA_REQUEST_SUCCESS'
export const GET_BUSINESS_DATA_REQUEST_FAILED = 'GET_BUSINESS_DATA_REQUEST_FAILED'

export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST'
export const GET_RESERVATIONS_REQUEST_SUCCESS = 'GET_RESERVATIONS_REQUEST_SUCCESS'
export const GET_RESERVATIONS_REQUEST_FAILED = 'GET_RESERVATIONS_REQUEST_FAILED'

export const GET_STATEMENTS_REQUEST = 'GET_STATEMENTS_REQUEST'
export const GET_STATEMENTS_REQUEST_SUCCESS = 'GET_STATEMENTS_REQUEST_SUCCESS'
export const GET_STATEMENTS_REQUEST_FAILED = 'GET_STATEMENTS_REQUEST_FAILED'

export const STATEMENTS_XLS_REQUEST = 'STATEMENTS_XLS_REQUEST'
export const STATEMENTS_REQUEST_XLS_SUCCESS = 'STATEMENTS_REQUEST_XLS_SUCCESS'
export const STATEMENTS_REQUEST_XLS_FAILED = 'STATEMENTS_REQUEST_XLS_FAILED'

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST';
export const DASHBOARD_DATA_REQUEST_SUCCESS = 'DASHBOARD_DATA_REQUEST_SUCCESS';
export const DASHBOARD_DATA_REQUEST_FAILED = 'DASHBOARD_DATA_REQUEST_FAILED';

export const BUSINESS_PERFORMANCE_DATA_REQUEST = 'BUSINESS_PERFORMANCE_DATA_REQUEST';
export const BUSINESS_PERFORMANCE_DATA_REQUEST_SUCCESS = 'BUSINESS_PERFORMANCE_DATA_REQUEST_SUCCESS';
export const BUSINESS_PERFORMANCE_DATA_REQUEST_FAILED = 'BUSINESS_PERFORMANCE_DATA_REQUEST_FAILED';

export const PARTNERS_DATA_REQUEST = 'PARTNERS_DATA_REQUEST';
export const PARTNERS_DATA_REQUEST_SUCCESS = 'PARTNERS_DATA_REQUEST_SUCCESS';
export const PARTNERS_DATA_REQUEST_FAILED = 'PARTNERS_DATA_REQUEST_FAILED';

export const BUSINESS_RESOURCE_DATA_REQUEST = 'BUSINESS_RESOURCE_DATA_REQUEST';
export const BUSINESS_RESOURCE_DATA_REQUEST_SUCCESS = 'BUSINESS_RESOURCE_DATA_REQUEST_SUCCESS';
export const BUSINESS_RESOURCE_DATA_REQUEST_FAILED = 'BUSINESS_RESOURCE_DATA_REQUEST_FAILED';

export const BUSINESS_REVIEWS_REQUEST = 'BUSINESS_REVIEWS_REQUEST';
export const BUSINESS_REVIEWS_REQUEST_SUCCESS = 'BUSINESS_REVIEWS_REQUEST_SUCCESS';
export const BUSINESS_REVIEWS_REQUEST_FAILED = 'BUSINESS_REVIEWS_REQUEST_FAILED';

export const BUSINESS_DETAILS_REQUEST = 'BUSINESS_DETAILS_REQUEST';
export const BUSINESS_DETAILS_REQUEST_SUCCESS = 'BUSINESS_DETAILS_REQUEST_SUCCESS';
export const BUSINESS_DETAILS_REQUEST_FAILED = 'BUSINESS_DETAILS_REQUEST_FAILED';

export const BUSINESS_SCHEDULE_REQUEST = 'BUSINESS_SCHEDULE_REQUEST';
export const BUSINESS_SCHEDULE_REQUEST_SUCCESS = 'BUSINESS_SCHEDULE_REQUEST_SUCCESS';
export const BUSINESS_SCHEDULE_REQUEST_FAILED = 'BUSINESS_SCHEDULE_REQUEST'

export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS'
export const SET_SELECTED_BUSINESS_SUCCESS= 'SET_SELECTED_BUSINESS_SUCCESS'

export const SET_SELECTED_CHAIN = 'SET_SELECTED_CHAIN'

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const GET_BUSINESS_EMAILS = 'GET_BUSINESS_EMAILS'
export const GET_BUSINESS_EMAILS_SUCCESS = 'GET_BUSINESS_EMAILS_SUCCESS'
export const GET_BUSINESS_EMAILS_FAILED = 'GET_BUSINESS_EMAILS_FAILED'

export const GET_BUSINESS_ACCOUNTS = 'GET_BUSINESS_ACCOUNTS'
export const GET_BUSINESS_ACCOUNTS_SUCCESS = 'GET_BUSINESS_ACCOUNTS_SUCCESS'
export const GET_BUSINESS_ACCOUNTS_FAILED = 'GET_BUSINESS_ACCOUNTS_FAILED'


export const GET_BUSINESS_ACCOUNTS_V2 = 'GET_BUSINESS_ACCOUNTS_V2'
export const GET_BUSINESS_ACCOUNTS_SUCCESS_V2 = 'GET_BUSINESS_ACCOUNTS_SUCCESS_V2'
export const GET_BUSINESS_ACCOUNTS_FAILED_V2 = 'GET_BUSINESS_ACCOUNTS_FAILED_V2'

export const POST_BUSINESS_ACCOUNTS = 'POST_BUSINESS_ACCOUNTS'
export const POST_BUSINESS_ACCOUNTS_SUCCESS = 'POST_BUSINESS_ACCOUNTS_SUCCESS'
export const POST_BUSINESS_ACCOUNTS_FAILED = 'POST_BUSINESS_ACCOUNTS_FAILED'

export const UPDATE_BUSINESS_ACCOUNTS = 'UPDATE_BUSINESS_ACCOUNTS'
export const UPDATE_BUSINESS_ACCOUNTS_SUCCESS = 'UPDATE_BUSINESS_ACCOUNTS_SUCCESS'
export const UPDATE_BUSINESS_ACCOUNTS_FAILED = 'UPDATE_BUSINESS_ACCOUNTS_FAILED'

export const UPDATE_BUSINESS_ACCOUNTS_V2 = 'UPDATE_BUSINESS_ACCOUNTS_V2'
export const UPDATE_BUSINESS_ACCOUNTS_SUCCESS_V2 = 'UPDATE_BUSINESS_ACCOUNTS_SUCCESS_V2'
export const UPDATE_BUSINESS_ACCOUNTS_FAILED_V2 = 'UPDATE_BUSINESS_ACCOUNTS_FAILED_V2'

export const DELETE_BUSINESS_ACCOUNTS = 'DELETE_BUSINESS_ACCOUNTS'
export const DELETE_BUSINESS_ACCOUNTS_SUCCESS = 'DELETE_BUSINESS_ACCOUNTS_SUCCESS'
export const DELETE_BUSINESS_ACCOUNTS_FAILED = 'DELETE_BUSINESS_ACCOUNTS_FAILED'

export const POST_BUSINESS_EMAILS = 'POST_BUSINESS_EMAILS'
export const POST_BUSINESS_EMAILS_SUCCESS = 'POST_BUSINESS_EMAILS_SUCCESS'
export const POST_BUSINESS_EMAILS_FAILED = 'POST_BUSINESS_EMAILS_FAILED'

export const UPDATE_BUSINESS_EMAILS = 'UPDATE_BUSINESS_EMAILS'
export const UPDATE_BUSINESS_EMAILS_SUCCESS = 'UPDATE_BUSINESS_EMAILS_SUCCESS'
export const UPDATE_BUSINESS_EMAILS_FAILED = 'UPDATE_BUSINESS_EMAILS_FAILED'

export const DELETE_BUSINESS_EMAILS = 'DELETE_BUSINESS_EMAILS'
export const DELETE_BUSINESS_EMAILS_SUCCESS = 'DELETE_BUSINESS_EMAILS_SUCCESS'
export const DELETE_BUSINESS_EMAILS_FAILED = 'DELETE_BUSINESS_EMAILS_FAILED'

export const CHANGE_BUSINESS_ACCOUNT_USER="CHANGE_BUSINESS_ACCOUNT_USER"

export const RESET_IS_AUTHORIZED = "RESET_IS_AUTHORIZED"

export const CREATE_BUSINESS_INFO_REQUEST = "CREATE_BUSINESS_INFO_REQUEST"
export const CREATE_BUSINESS_INFO_REQUEST_SUCCESS = "CREATE_BUSINESS_INFO_REQUEST_SUCCESS"
export const CREATE_BUSINESS_INFO_REQUEST_FAILED = "CREATE_BUSINESS_INFO_REQUEST_FAILED"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

export const UPDATE_PASSWORD_REQUEST= "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_REQUEST_SUCCESS = "UPDATE_PASSWORD_REQUEST_SUCCESS"
export const UPDATE_PASSWORD_REQUEST_FAILED = "UPDATE_PASSWORD_REQUEST_FAILED"

export const UPLOAD_BUSINESS_IMAGE = 'UPLOAD_BUSINESS_IMAGE'
export const UPLOAD_BUSINESS_IMAGE_SUCCESS = 'UPLOAD_BUSINESS_IMAGE_SUCCESS'
export const UPLOAD_BUSINESS_IMAGE_FAILED = 'UPLOAD_BUSINESS_IMAGE_FAILED'

export const GET_BILL_PAYMENTS_REQUEST = "GET_BILL_PAYMENTS_REQUEST"
export const GET_BILL_PAYMENTS_SUCCESS = "GET_BILL_PAYMENTS_SUCCESS"
export const GET_BILL_PAYMENTS_FAILED = "GET_BILL_PAYMENTS_FAILED"

export const GET_UNPAID_BALANCE_REQUEST = "GET_UNPAID_BALANCE_REQUEST"
export const GET_UNPAID_BALANCE_SUCCESS = "GET_UNPAID_BALANCE_SUCCESS"
export const GET_UNPAID_BALANCE_FAILED = "GET_UNPAID_BALANCE_FAILED"

export const UNSUBSCRIBE_FROM_EMAILS = "UNSUBSCRIBE_FROM_EMAILS"
export const UNSUBSCRIBE_EMAILS_SUCCESS = "UNSUBSCRIBE_EMAILS_SUCCESS"

export const CONFIRM_RESERVATION_REQUEST = "CONFIRM_RESERVATION_REQUEST" 
export const CONFIRM_RESERVATION_SUCCESS = "CONFIRM_RESERVATION_SUCCESS"

export const REJECT_RESERVATION_REQUEST = "REJECT_RESERVATION_REQUEST"
export const REJECT_RESERVATION_SUCCESS = "REJECT_RESERVATION_SUCCESS"

export const CONFIRM_RESERVATION_REQUEST_FAILED = "CONFIRM_RESERVATION_REQUEST_FAILED"
export const IS_REQUESTING_CONFIRMATION = "IS_REQUESTING_CONFIRMATION"