import React from "react";
import ReservationHistoryTable from "../../components/DataTables/InvoicedReservationTable";
import OrderHistoryTable from "../../components/DataTables/InvoicedOrderTable";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { connect } from "react-redux";
import {
  getStatementsRequest,
  statementsXlsDataRequest
} from "../../actions/businessDataActions";
import DropDown from "../../components/Dropdown/index";
import MenuBar from "../Home/components/MenuBar";
import { dateRanges, defaultDateRange } from "./dateRanges";

class History extends React.Component {

  getAllTimes(){
    return {
      end_time: this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - 1000)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm"),
    }
  };

  getLastXDays(item) {
    var new_item = null;
    var months = null;
    for (var i = 1; i < 12; i++) {
      if (item === 30 * i) {
        var start_month = this.$moment().month() - i;
        var end_month = this.$moment().month();
        if (end_month - start_month > 0) {
          months = Number(end_month - start_month);
        } else {
          months = Number(12 + end_month - start_month);
        }
        for (var j = 1; j <= months; j++) {
          if (this.$moment().month() - j === (3 || 5 || 8 || 10)) {
            new_item += 30;
          } else if (this.$moment().month() - j === 1) {
            new_item += 28;
          } else {
            new_item += 31;
          }
        }
      }
    }
    if (!new_item) {
      new_item = item;
    }
    return {
      end_time: this.$moment()
        .date(this.$moment().date())
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm"),
      start_time: this.$moment()
        .date(this.$moment().date() - new_item)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm")
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {
        page_number: 0,
        page_limit: 10,
        order_by_desc: true,
        order_by: "startTimeAsIfUtc",
        product: "DINE_IN",
        date_range: defaultDateRange,
        show_custom_range_datepickers: false,
        ...this.getAllTimes(),
      },
      business_id: parseInt(this.props.match.params.id),
      domain_type: this.props.match.params.entity
    };
  }
  $moment = moment;

  componentDidMount() {
    window.gtag("event", "History_page_viewed")
  }

  onSelect = (string_item, options) => {
    if (string_item === "custom_range") {
      if (!options) {
        this.setState({ data: {...this.state.data, date_range: string_item, show_custom_range_datepickers: true}});
        return;
      }
       start_time = this.$moment(options.start_time).format("YYYY-MM-DD HH:mm");
       end_time = this.$moment(options.end_time)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm");
      var state = Object.assign(this.state.data, {
        start_time: start_time,
        end_time: end_time,
        page_number: 0,
        page_limit: 10,
        date_range: string_item,
        business_ids:
          this.state.domain_type === "chain" ? null : this.state.business_id
      });
      this.setState({ data: state }, () => this.props.getStatements(state));
      return;
    }

    if (string_item !== "All") {
      if (string_item !== "") {
        var item = string_item.match(/\d/g);
        if (item) {
          item = Number(item.join(""));
          var { end_time, start_time } = this.getLastXDays(item);
        } else if (string_item === "Yesterday") {
          start_time = this.$moment()
            .date(this.$moment().date() - 1)
            .hours(0)
            .minutes(0)
            .format("YYYY-MM-DD HH:mm");
          end_time = this.$moment()
            .date(this.$moment().date() - 1)
            .hours(23)
            .minutes(59)
            .format("YYYY-MM-DD HH:mm");
        } else if (string_item === "Reservations5thJune") {
        }
      } else {
        start_time = this.$moment(options.start_time).format(
          "YYYY-MM-DD HH:mm"
        );
        end_time = this.$moment(options.end_time)
          .hours(23)
          .minutes(59)
          .format("YYYY-MM-DD HH:mm");
      }
    } else {
      end_time = this.$moment()
        .date(this.$moment().date() - 1)
        .hours(23)
        .minutes(59)
        .format("YYYY-MM-DD HH:mm");
      start_time = this.$moment()
        .date(this.$moment().date() - 1000)
        .hours(0)
        .minutes(0)
        .format("YYYY-MM-DD HH:mm");
    }

    var state = Object.assign(this.state.data, {
      start_time: start_time,
      end_time: end_time,
      page_number: 0,
      page_limit: 10,
      date_range: string_item,
      show_custom_range_datepickers: false
    });
    this.setState({ data: state }, () => this.props.getStatements(state));
  };

  exportHandler = () => {
    this.props.statementsXlsDataRequest(this.state.data);
  };

  // todo: remove if not being used
  // changeFilterBy = params => {
  //   var state = Object.assign(this.state.data, {
  //     business_id: parseInt(this.props.match.params.id),
  //     product: params,
  //     page_number: 0,
  //     page_limit: 10,
  //     order_by_desc: true,
  //     date_range: this.state.data.date_range
  //   });
  //   this.setState({ data: state },() => this.props.getStatements(state)); 
  // };

  handleRequest = tableState => {
    let order_by_dine_in = tableState.sorted.length > 0 ? tableState.sorted[0].id : "startTimeAsIfUtc";
    let data = {
      product: this.state.data.product,
      page_number: tableState.page,
      page_limit: tableState.pageSize,

      order_by_desc:
        tableState.sorted.length > 0 && tableState.sorted[0].desc === false
          ? true : false,
      order_by:
        this.state.data.product === "DINE_IN" ? order_by_dine_in : "createdAt",
      start_time: this.state.data.start_time,
      end_time: this.state.data.end_time,
      date_range: this.state.data.date_range,
      business_ids:
        this.state.domain_type === "chain" ? null : this.state.business_id
    };

    this.setState({ data: data });
    this.props.getStatements(data);
  };

  handleDateRangeBtnClick = (selectedDateRange, options) => {
    console.log({selectedDateRange});
    this.setState({data: {...this.state.data, date_range: selectedDateRange}});
    this.onSelect(selectedDateRange, options);
  }

  render() {
    let detailView;
    const product = this.state.data.product;
    if (product == "DINE_IN") {
      detailView =  <ReservationHistoryTable
                      page={this.state.data.page_number}
                      data={this.props.statements}
                      handleRequest={this.handleRequest}
                      loading={this.props.isFetchingStatements}
                    />
    } else {
      detailView =  <OrderHistoryTable
                      page={this.state.data.page_number}
                      data={this.props.statements}
                      handleRequest={this.handleRequest}
                      loading={this.props.isFetchingStatements}
                    />
    }
    return (
      <div className="custom-gradient">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <MenuBar 
              title={"History"}
              dateRange={this.state.data.date_range}
              dateChangeHandler={this.handleDateRangeBtnClick}
              showDownloadButton={Object.keys(this.props.statements).length > 0 && this.props.statements.objects.length > 0}
              downloadButtonText={this.props.isDownloadingStatements ? "Downloading" : "Export Results"}
              showAnimatedDots={this.props.isDownloadingStatements}
              downloadButtonDisabled={this.props.isDownloadingStatements}
              downloadButtonHandler={this.exportHandler}
              showDateRangeButtons={true}
              dateRanges={dateRanges}
              specialBtnProps={{handleSelect: this.handleDateRangeBtnClick, isVisible: this.state.data.show_custom_range_datepickers}}
            />
          </Grid>

          <Grid item xs={12} className="margin-left-right-40px" style={{ marginTop: "24px" }}>
            {detailView}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    statements: state.businessData.statements,
    isAuthorized: state.auth.isAuthorized,
    isFetchingStatements: state.businessData.isFetchingStatements,
    isDownloadingStatements: state.businessData.isDownloadingStatements
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStatements: data => {
      dispatch(getStatementsRequest(data));
    },
    statementsXlsDataRequest: data => {
      dispatch(statementsXlsDataRequest(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
