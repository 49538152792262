import React from "react";
import { Grid } from "@material-ui/core";
import BoxViewV2 from "../../../../../components/BoxViewV2/index";
import PercentageCircle from "../../PercentageCircle";
import BorderHorizontal from "../../../../../components/BorderHorizontal";

const CustomerTypeSection = (props) => {
  return (
    <Grid
      container
      className="business-insights-card margin-left-right-40px margin-top-30px padding-bottom-40px"
    >
      <Grid item xs={12} className="margin-left-32px">
        <div className="margin-right-auto primary-normal-font font-size-12 font-weight-bold margin-top-bottom-40px display-flex">
          CUSTOMER TYPES
        </div>
      </Grid>
      <Grid container item xs={12} md={6} className="padding-right-left-24px">
        <Grid
          item
          xs={6}
          className="display-flex justify-content-center align-items-center flex-direction-column"
        >
          <PercentageCircle
            percentage={props.showDefaultScreen ? 70 : props.data.statsPerDateRange[props.selectedDateRange].customerStats.NEW_CUSTOMER.distribution}
            percentageText={props.showDefaultScreen ? "00" : null}
            percentageTextClass={props.showDefaultScreen ? "opacity-20" : ""}
            color={props.showDefaultScreen ? "rgba(242, 189, 14, 0.1)" : "rgba(242, 189, 14, 1)"}
          />
          <p className="primary-normal-text font-weight-bold font-size-11">
            New Customer
          </p>
        </Grid>
        <Grid
          item
          xs={6}
          className="display-flex justify-content-center align-items-center flex-direction-column"
        >
          <PercentageCircle
            percentage={props.showDefaultScreen ? 30 : props.data.statsPerDateRange[props.selectedDateRange].customerStats.REPEAT_CUSTOMER.distribution}
            percentageText={props.showDefaultScreen ? "00" : null}
            percentageTextClass={props.showDefaultScreen ? "opacity-20" : ""}
            color={props.showDefaultScreen ? "rgba(255, 229, 143, 0.1)" : "rgba(255, 229, 143, 1)"}
          />
          <p className="primary-normal-text font-weight-bold font-size-11">
            Repeat Customer
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className="padding-right-left-24px">
        <Grid className="" container direction="row">
          <Grid item xs={4}>
            <p className="font-size-12 primary-normal-font primary-text-color line-height-15px opacity-50 margin-top-0 margin-bottom-8px">
              {props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].customerStats.NEW_CUSTOMER.distribution}%
            </p>
            <BoxViewV2
              containerClasses={""}
              number={props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].customerStats.NEW_CUSTOMER.totalNumberOfCustomers}
              numberClasses={props.showDefaultScreen ? "opacity-20" : ""}
              primaryTextFont={"font-size-30"}
              subtitle={"NEW CUSTOMERS"}
            />
          </Grid>
          <Grid item>
            <p className="font-size-12 primary-normal-font primary-text-color line-height-15px opacity-50 margin-top-0 margin-bottom-8px padding-left-right-16px">
              {props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].customerStats.REPEAT_CUSTOMER.distribution}%
            </p>
            <BoxViewV2
              containerClasses={"padding-left-right-16px"}
              number={props.showDefaultScreen ? "00" : props.data.statsPerDateRange[props.selectedDateRange].customerStats.REPEAT_CUSTOMER.totalNumberOfCustomers}
              numberClasses={props.showDefaultScreen ? "opacity-20" : ""}
              primaryTextFont={"font-size-30"}
              subtitle={"REPEAT CUSTOMERS"}
            />
          </Grid>
          <Grid item xs={12} className="margin-right-10px">
            <BorderHorizontal classes="opacity-10 width-initial padding-top-24px" />
          </Grid>
          <Grid item className="description-text">
            <p className="font-size-12 opacity-50 padding-top-12px">
              Our goal is to help you fill empty tables by introducing your
              restaurant to customers across your city. As a Seated partner,
              your restaurant is featured to a broad range of customer
              demographics across the Seated App and our Partner Network.
            </p>
            <p className="font-size-12 opacity-50 padding-top-12px">
              Seated runs targeted CRM campaigns to attract new customers who
              are dining for the first time and to re-engage previous guests who
              are encouraged to visit again.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerTypeSection;
