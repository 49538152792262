import React, { useEffect, useRef, useState } from "react";

const PercentageCircle = (props) => {
  const [offset, setOffset] = useState(100); // Start from 100 to animate to the target
  const circleRef = useRef(null); // Reference for the circle element

  useEffect(() => {
    const percentageOffset = 100 - props.percentage; // Calculate the offset
    setOffset(percentageOffset); // Set the calculated offset to animate
  }, [props.percentage]);

  return (
    <div className="position-relative" style={{ width: '100%', height: '100%', maxWidth: '300px', maxHeight: '300px', position: 'relative' }}>
      <svg width="100%" height="100%" viewBox="0 0 70 70" transform="rotate(-90)">
        <circle
          cx="35"
          cy="35"
          r="30"
          fill="none"
          stroke="#EAEAEA"
          strokeWidth="4"
        ></circle>
        <circle
          ref={circleRef}
          cx="35"
          cy="35"
          r="30"
          fill="none"
          stroke={props.color}
          strokeWidth="4"
          strokeLinecap="round"
          pathLength="100"
          strokeDasharray="100"
          strokeDashoffset={offset} // Use the animated offset
          style={{
            transition: "stroke-dashoffset 1s ease-in-out", // Add transition effect
          }}
          transform="rotate(-180, 35, 35)"
        ></circle>
      </svg>
      <span className={`primary-normal-font font-size-24 font-weight-450 position-absolute circular-status-bar-text ${props.percentageTextClass}`}>
        {props.percentageText ? props.percentageText : props.percentage}%
      </span>
    </div>
  );
};

export default PercentageCircle;
