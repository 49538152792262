import React from "react";
import MonthlySalesPerformance from "./components/MonthlyPerformanceSection";
import SalesDistributionSection from "./components/SalesDistributionSection";
import CustomerTypeSection from "./components/CustomerTypeSection";
import { Grid } from "@material-ui/core";
import { PAST_12_MONTHS } from "../../dateRanges";

const BusinessPerformanceSection = (props) => {
  const showDefaultScreen = props.data.statsPerDateRange[PAST_12_MONTHS].totalGmv == 0;

  return (
    <>
      <Grid container>
        {showDefaultScreen ? 
          (<Grid item xs={12}>
            <div id="business-performance-data-unavailable-message-div" className="border-radius-12px font-size-12 primary-bold-font border-radius-12px" style={{backgroundColor: '#FFE9A2'}}>
              Your business performance data will populate after 2 to 3 months with Seated
            </div>
          </Grid>) : ''}
        <Grid item xs={12}>
          <div className="margin-right-auto primary-normal-font font-size-12 font-weight-bold margin-top-30px margin-bottom-20px margin-left-40px">
            MONTHLY SALES PERFORMANCE
          </div>
          </Grid>
      </Grid>
      <MonthlySalesPerformance selectedDateRange={props.selectedDateRange} data={props.data} showDefaultScreen={showDefaultScreen} />
      <SalesDistributionSection selectedDateRange={props.selectedDateRange} data={props.data} showDefaultScreen={showDefaultScreen} />
      <CustomerTypeSection selectedDateRange={props.selectedDateRange} data={props.data} showDefaultScreen={showDefaultScreen} />
    </>
  );
};

export default BusinessPerformanceSection;
