import React from "react";

const BoxView = ({ containerClasses, number, primaryTextFont, hasDollars, subtitle, numberClasses = '', subtitleClasses }) => {
  return (
    <div className={`flex-wrap ${containerClasses}`}>
      <div className={`primary-text-color secondary-normal-font ${primaryTextFont ? primaryTextFont : "font-size-28"} ${numberClasses}`}>
        {hasDollars === true ? "$" : ""}
        {number ? number.toLocaleString() : 0}
      </div>
      <div className={`primary-text-color opacity-50 primary-bold-font font-size-12 font-weight-bold letter-spacing-0-11em margin-top-10px ${subtitleClasses}`}>
        {subtitle}
      </div>
    </div>
  );
};

export default BoxView;
