export const PAST_3_MONTHS = "PAST_3_MONTHS";
export const PAST_6_MONTHS = "PAST_6_MONTHS";
export const PAST_12_MONTHS = "PAST_12_MONTHS";

export const defaultDateRange = PAST_6_MONTHS;
export const dateRanges = [
  { displayText: "Past 6 Months", value: PAST_6_MONTHS },
  { displayText: "Past 3 Months", value: PAST_3_MONTHS },
  { displayText: "Past 12 Months", value: PAST_12_MONTHS },
];

export function monthlySalesPerformanceTooltipCreator(tooltipItem) {
  const dataObj =
    tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].detailedData[
      tooltipItem.dataIndex
    ];
  const sales = "$" + dataObj.gmv.toLocaleString() + " in sales";
  const transactions = dataObj.numberOfTransactions.toLocaleString() + " transactions";
  const averageSpend = "$" + dataObj.averageSpend.toLocaleString() + " avg spend";

  return `${sales}\n${transactions}\n${averageSpend}`;
}

export function weeklySalesDistributionTooltipCreator(tooltipItem) {
  const dataObj =
    tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].detailedData[
      tooltipItem.dataIndex
    ];
  const distribution = dataObj.distribution + "% of weekly sales";
  const averageSales = "$" + dataObj.averageSales.toLocaleString() + " avg sales";

  return `${distribution}\n${averageSales}`;
}