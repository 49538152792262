import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the scales and other components you need
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
Chart.defaults.font.family = "CircularStd-Bold";

function yTicksCallbackDefault(value) {
  return value;
}

function configureTooltip(tooltipFunction) {
  return {
    enabled: true,
    caretSize: 0,
    padding: {
      left: 10,
      right: 50,
      top: 25,
      bottom: 25,
    },
    callbacks: {
      title: () => "", // Empty title in tooltip
      label: () => "", // Empty label in tooltip
      afterLabel: tooltipFunction,
    },
    filter: function(tooltipItem) {
      // Get the label of the current tooltip item
      const label = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex].label;
      // If the label is "Pending", return false to disable tooltip
      if (label === "Pending") {
        return false;
      }
      // Otherwise, show the tooltip
      return true;
    },
  };
}

function configureLegend(showLegend) {
  return {
    position: "bottom",
    display: showLegend,
    align: "start",
    labels: {
      color: "black",
      boxWidth: 14,
      boxHeight: 14,
      font: {
        size: 8,
        weight: "bolder",
      },
    },
  };
}

function configureScales(yTicksCallback, xTicksFontSize, yTicksMaxValue) {
  return {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "black",
        font: {
          size: xTicksFontSize,
        },
      },
    },
    y: {
      max: yTicksMaxValue,
      grid: {
        drawBorder: false,
      },
      ticks: {
        padding: 10,
        color: "black",
        callback: yTicksCallback,
      },
    },
  };
}

function createOptions(tooltipFunction, showLegend, yTicksCallback, xTicksFontSize, yTicksMaxValue, onClickHandler) {
  return {
    responsive: true,
    plugins: {
      legend: configureLegend(showLegend),
      tooltip: configureTooltip(tooltipFunction),
    },
    scales: configureScales(yTicksCallback, xTicksFontSize, yTicksMaxValue),
    onClick: onClickHandler
    // barThickness: 45,  // Decide if needed
  };
}

const BarChart = ({ data, tooltipFunction, showLegend = true, yTicksCallback = yTicksCallbackDefault, xTicksFontSize = 12, yTicksMaxValue = null, onClickHandler = null }) => {
  const options = createOptions(tooltipFunction, showLegend, yTicksCallback, xTicksFontSize, yTicksMaxValue, onClickHandler);
  return <Bar options={options} data={data} />;
};

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  tooltipFunction: PropTypes.func,
  showLegend: PropTypes.bool,
  yTicksCallback: PropTypes.func,
  xTicksFontSize: PropTypes.number,
};

export default BarChart;
